import styles from './/Footer.css'

let currentYear = new Date().getFullYear()  // returns the current year

const Footer = () => {
    return (
        <div className="footersection">
            <p>Copyright &#169; Katherine Rock {currentYear}</p>
        </div>
    )
}

export default Footer;